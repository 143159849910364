import axios from 'axios';                                                                                                                          
                                                                                                                                                       
const api = axios.create({                                                                                                                          
  baseURL: 'https://geofeed-api.netcoms.rs/api/v1',
//  headers: {
//    'Content-Type': 'application/json',
//    'Authorization': 'Bearer ' + localStorage.getItem('token'),
//  },
});

const apih = axios.create({
  baseURL: 'https://geofeed-api.netcoms.rs/api/v1',
  headers: {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem('token'),
  },
});
export { apih };
export default api;
