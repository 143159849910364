<template>                                                                   
    <nav v-if="!isMobile()" class="navigation">                                                   
      <div class="navigation__logo">                                           
        <img src="../assets/logo.png" alt="Logo" class="navigation__logo-image">
        <!--<img src="https://oriontelekom.rs/wp-content/uploads/2016/08/logo.png" alt="Logo" class="navigation__logo-image">-->
      </div>                                                                   
      <ul class="navigation__list">                                            
        <li class="navigation__item">                                          
          <router-link to="/" class="navigation__link">Home</router-link>      
        </li>
        <li class="navigation__item" ref="subscribersDropdown" @mouseleave="hideDropdown">
          <span class="navigation__link" @mouseover="showDropdown('subscribers')">IP Blocks</span>
          <ul class="dropdown-menu" :class="{ 'show': activeDropdown === 'subscriber' }">
          <!-- Dropdown menu items go here -->
          <li><router-link to="/ip/list" class="navigation__link">List</router-link></li>
          <li><router-link to="/ip/add" class="navigation__link">Add block to list</router-link></li>
          </ul>
        </li>
        <li class="navigation__item">
          <router-link to="/download" class="navigation__link">Download CSV</router-link>
        </li>
        <li class="navigation__item" ref="adminDropdown" @mouseleave="hideDropdown" v-if="isAdmin">
          <span class="navigation__link" @mouseover="showDropdown('admin')">Administration</span>
          <ul class="dropdown-menu" :class="{ 'show': activeDropdown === 'admins' }">
          <!-- Dropdown menu items go here -->
          <li><router-link to="/admin/users" class="navigation__link">Users</router-link></li>
          <li><router-link to="/admin/password" class="navigation__link">Password change</router-link></li>
          <li><router-link to="/admin/settings" class="navigation__link">Global settings</router-link></li>
          </ul>
        </li>
        <li class="navigation__item" v-if="isLoggedIn">
          <router-link to="/settings" class="navigation__link">Settings</router-link>
        </li>
        <li class="navigation__item" v-if="!isLoggedIn">                                          
          <router-link to="/login" class="navigation__link">Login</router-link>
        </li>
        <li class="navigation__item" v-if="isLoggedIn">                                          
          <router-link to="/logout" class="navigation__link" @click="logout">Logout</router-link>
        </li>
      </ul>                                                                    
    </nav>
    <nav v-else class="navigation_mobile">                                                   
      <div class="navigation__logo_mobile">                                           
        <img src="https://oriontelekom.rs/wp-content/uploads/2016/08/logo.png" alt="Logo" class="navigation__logo-image_mobile">                                              
      </div>                                                                   
      <ul class="navigation__list_mobile">                                            
        <li class="navigation__item_mobile">                                          
          <router-link to="/" class="navigation__link_mobile">Home</router-link>      
        </li>                                                                  
        <li class="navigation__item_mobile">                                          
          <router-link to="/ip/list" class="navigation__link_mobile">List</router-link>                                                           
        </li>
        <li class="navigation__item_mobile">
          <router-link to="/ip/add" class="navigation__link_mobile">Add block to list</router-link>
        </li>
        <li class="navigation__item_mobile">
          <router-link to="/download" class="navigation__link_mobile">Download CSV</router-link>
        </li>
        <li class="navigation__item_mobile" v-if="!isLoggedIn">                                          
          <router-link to="/login" class="navigation__link_mobile">Login</router-link>
        </li>
        <li class="navigation__item_mobile" v-if="isLoggedIn">                                          
          <router-link to="/logout" class="navigation__link_mobile" @click="logout">Logout</router-link>
        </li>
      </ul>                                                                    
    </nav>
</template>

  <script>
  import VueJwtDecode from 'vue-jwt-decode';                                                               
  export default {                                                             
    name: 'AppNavigation',
    data() {                                                                                                                                          
      return {
        isLoggedIn: false,
        isAdmin: false,
        isDropdownVisible: false,
        activeDropdown: null,
      };                                                                                                                                              
    },
    mounted() {
      //this.$store.commit('setLoggedIn', false);
      const accessToken = localStorage.getItem('token');
      this.isLoggedIn = !!accessToken;
      //set admin based on db
      this.isAdmin = this.checkAdmin();
    },
    created() {                                                                                                                                      
      const accessToken = localStorage.getItem('token');
      this.isLoggedIn = !!accessToken; // Update the isLoggedIn state based on the presence of the access token                                                                                              
    },
    methods: {
      showDropdown(identifier) {
        this.activeDropdown = identifier;
      },
      hideDropdown() {
        this.activeDropdown = null;
      },
      isMobile() {

        if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
          return true
        } else {
          return false
        }
      },
      checkAdmin() {
        let token = localStorage.getItem('token');
        try {
          let decoded = VueJwtDecode.decode(token);
          let current_user = decoded['admin'];
          if(current_user == 1) {
            return true
          } else {
            return false
          }
        } catch(err) {
          console.log('token is null: ', err);
        }
      },                                                                                                                                     
      logout() {
            // Clear the user's session or access token from client-side storage                                                                      
            localStorage.removeItem('token');

            // Update the app's state to reflect that the user is logged out                                                                          
            //this.$store.commit('setLoggedIn', false);

            // Redirect the user to the login page or any other appropriate page                                                                      
            this.$router.push('/login');                                                                                                                                       
      }                                                                                                                                               
    }
  };                                                                           
  </script>                                                                    
                                                                               
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;1,100&display=swap');
.navigation_mobile {
  position: fixed;                                                           
  top: 0;                                                                    
  left: 0;                                                                
  width: 100%;                                                               
  background-color: #000000;                                                    
  padding: 10px;                                                             
  display: flex;                                                             
  justify-content: space-between;                                            
  align-items: center;                                                       
  z-index: 999;
}
.navigation__logo_mobile {                                                          
  margin-right: 20px;                                                        
}                                                                            
                                                                             
.navigation__logo-image_mobile {                                                    
  width: 85px;                                                               
  height: 30px;                                                              
}                                                                            
                                                                             
.navigation__list_mobile {                                                          
  list-style-type: none;                                                     
  padding: 0;                                                                
  margin: 0;                                                                 
  display: flex;                                                             
}                                                                            
                                                                             
.navigation__item_mobile {                                                          
  margin-right: 20px;                                                        
}                                                                            
                                                                             
.navigation__link_mobile {  
  /* change font to Lato */
  font-family: 'Lato', sans-serif;
  text-decoration: none;                                                     
  color: #fff;                                                               
  font-size: 8px;                                                           
  /*font-weight: bold; */
  padding: 4px;                                                             
  transition: color 0.3s ease-in-out;                                        
}                                                                            
                                                                             
.navigation__link_mobile:hover {                                                    
  color: #42f86f;                                                            
}
.navigation {                                                                
  position: fixed;                                                           
  top: 0;                                                                    
  left: 0;                                                                   
  width: 100%;                                                               
  background-color: #000000;                                                    
  padding: 15px;                                                             
  display: flex;                                                             
  justify-content: space-between;                                            
  align-items: center;                                                       
  z-index: 999;                                                              
}                                                                            
                                                                             
.navigation__logo {                                                          
  margin-right: 20px;                                                        
}                                                                            
                                                                             
.navigation__logo-image {                                                    
  width: 35px;                                                               
  height: 35px;                                                              
}                                                                            
                                                                             
.navigation__list {                                                          
  list-style-type: none;                                                     
  padding: 0;                                                                
  margin: 0;                                                                 
  display: flex;                                                             
}                                                                            
                                                                             
.navigation__item {                                                          
  margin-right: 20px;
  position: relative;
}                                                                            
                                                                             
.navigation__link {  
  /* change font to Lato */
  font-family: 'Lato', sans-serif;
  text-decoration: none;                                                     
  color: #fff;                                                               
  font-size: 16px;                                                           
  /*font-weight: bold; */
  padding: 10px;                                                             
  transition: color 0.3s ease-in-out;                                        
}                                                                            
                                                                             
.navigation__link:hover {                                                    
  color: #42f86f;                                                            
}
.dropdown-menu {
  position: absolute;
  top: 100%; /* Position the dropdown menu below the menu item */
  left: 0;
  background-color: #000000;
  list-style-type: none;
  padding: 10px;
  display: none; /* Hide the dropdown menu by default */
}

.dropdown-menu li {
  margin-bottom: 10px;
}

.navigation__item:hover .dropdown-menu {
  display: block; /* Show the dropdown menu when hovering over the menu item */
}
</style>
