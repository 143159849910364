<template>
    <div>                                                                      
      <h1>Overview</h1>
    </div>
</template>

<script>
export default {                                                             
  name: 'adminPage',                                                              
  components: {
  },
  data() {
  },
};
</script>